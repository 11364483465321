






















































import SeletorDeUsuario from '@/components/usuario/SeletorDeUsuario.vue'
import { ModalidadeDeVenda, TurnoDeVenda, Venda } from '@/models'
import type { Loja } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import { FindModalidadeDeVendaUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'

@Component({
	components: {
		SeletorDeUsuario,	
	},
})
export default class DialogoDeInformarModalidade extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop() loja!: Loja
	@Prop({ type: Boolean, default: false }) bloqueiaSeletor!: boolean

	carregandoModalidade: boolean | false = false
	mostra: boolean | null = false
	modalidades: ModalidadeDeVenda[] | [] = []
	modalidade: ModalidadeDeVenda | null = null
	findModalidadeDeVendaUseCase = new FindModalidadeDeVendaUseCase()

	get venda() {
		return VendaModule.vendaAtual as Venda
	}
	
	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get turno() {
		return VendaModule.turnoDeVenda as TurnoDeVenda
	}

	mostrar() {
		this.mostra = true
		this.modalidade = VendaModule.vendaAtual?.modalidadeDeVenda || null
	}

	fechar(){
		this.mostra = false
	}

	informarModalidade() {
		this.venda.modalidadeDeVenda = this.modalidade

		this.mostra = false
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean, mostraAnterior: boolean) {
		if (mostra && !mostraAnterior) {
			this.carregar()
			this.modalidade = this.venda.modalidadeDeVenda
			window.requestAnimationFrame(() => {
				this.form.resetValidation()
			})
		}
	}
	
	get ehUmaTroca() {
		if(!this.venda) return false
		return (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros')
			&& this.venda.itens.filter(item => item.quantidade > 0).length > 0 
	}

	async carregar(): Promise<Array<ModalidadeDeVenda>> {
		this.carregandoModalidade = true
		let params;
		if(this.venda.isConsignado || this.venda.isDemonstracao) {
			params = {
				isDevolucao:false,
				isDemonstracao:true,
				isVenda:false,
			}
		}else if(this.ehUmaTroca){
			params = {
				isDevolucao:false,
				isDemonstracao:false,
				isVenda:true,
			}
		}else if (this.venda.temDevolucao || ['Devolução', 'Outros'].includes( this.venda.tipoDeTransacao)) {
			params = {
				isDevolucao:true,
				isDemonstracao:false,
				isVenda:false,
			}
		}else{
			params = {
				isDevolucao:false,
				isDemonstracao:false,
				isVenda:true,
			}
		}
		const listaDeModalidades = await this.findModalidadeDeVendaUseCase.findModalidadesDaOrigem(this.loja.id, params)
		this.modalidades = listaDeModalidades.map(modalidadeMap => {
			return {
				id: modalidadeMap.id,
				modalidade: modalidadeMap.modalidade,
				origem: modalidadeMap.origem,
				lojaId: (modalidadeMap as any).loja.id,
				sequencial: modalidadeMap.sequencial,
				modalidadePadrao: modalidadeMap.modalidadePadrao,
			}
		})
		this.carregandoModalidade = false
		
		return listaDeModalidades
	}

}
