























































































































































































































































































































































































































































































































































































































































































import {
	Vue,
	Component,
	PropSync,
	Watch,
	Ref,
	Prop,
} from 'vue-property-decorator'
import type { Venda } from '@/models'
import {	
	FormPagamento,
	Loja,
	DetalhesDoPagamento,
	Pagamento,
	Desconto,
	CupomDeDesconto,
	UsuarioBase,
} from '@/models'
import { formatarMoeda, fixarCasasDecimais } from '@/shareds/formatadores'
import {
	obterItensComTaxaAplicada,
	obterRestanteDaVenda,
	obterSomaTotalDaTaxaPorItem,
	obterTotalDeTaxas,
	obterTotalDaVenda,
	obterTotalDosItensDaVenda,
	obterTrocoDaVenda,
	obterValorAPagar,
	obterTotalDosItensSemDesconto,
	obterDescontoDaVenda,
} from '@/shareds/venda-shareds'
import ResumoDaVenda from './ResumoDaVenda.vue'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'
import { ParcelasCrediario, TipoDePagamento } from '@/models/venda/TipoDePagamento'
import MatrizDeTiposDePagamento from './MatrizDeTiposDePagamento.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import PluginModule from '@/store/vuex/aplicacao/PluginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeEdicaoDePagamento from '@/components/venda/DialogoDeEdicaoDePagamento.vue'
import DialogoDeEdicaoDeFrete from '@/components/venda/DialogoDeEdicaoDeFrete.vue'
import { criarFormPagamento } from '@/shareds/shared-pagamento'
import { comparacaoDiatrica } from '@/shareds/utils'
import DialogoDeCupons from '@/components/venda/DialogoDeCupons.vue'
import type { 
	PagamentoServiceAdapter, 
	ModalidadeDeVendaServiceAdapter, 
	CashbackServiceAdapter, 
	DetalhesDoPagamentoServiceAdapter, 	 
} from '@/usecases'
import { FindClienteUseCase } from '@/usecases'
import { Inject } from 'inversify-props'
import { AxiosError } from 'axios'
import SeletorDeBandeira from '@/views/application/venda/SeletorDeBandeira.vue'
import DialogoDeInformarVendedor from '@/views/application/venda/DialogoDeInformarVendedor.vue'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import AvatarDeUsuario from '../usuario/AvatarDeUsuario.vue'
import DialogoDeEdicaoInformacoesAdicionais from './DialogoDeEdicaoInformacoesAdicionais.vue'
import SeletorDeAdquirente from '@/views/application/venda/SeletorDeAdquirente.vue'
import DialogoDeInformarModalidade from '@/views/application/venda/DialogoDeInformarModalidade.vue'
import { ClienteGrupoEconomico } from '@/models/GrupoEconomico'
import DialogoDeEdicaoDeParcelas from './DialogoDeEdicaoDeParcelas.vue'
import DialogoDeValePresente from '../valepresente/DialogoDeValePresente.vue'
import { ValePresente } from '@/models/views/ValePresente'
import DialogoDeSelecaoEnderecoCliente from './DialogoDeSelecaoEnderecoCliente.vue'

@Component({
	components: {
		ResumoDaVenda,
		CampoDecimal,
		MatrizDeTiposDePagamento,
		Confirmacao,
		DialogoDeEdicaoDePagamento,
		DialogoDeCupons,
		DialogoDeEdicaoDeFrete,
		SeletorDeBandeira,
		DialogoDeInformarVendedor,
		AvatarDeUsuario,
		DialogoDeEdicaoInformacoesAdicionais,
		SeletorDeAdquirente,
		DialogoDeInformarModalidade,
		DialogoDeEdicaoDeParcelas,
		DialogoDeValePresente,
		DialogoDeSelecaoEnderecoCliente,
	},
})
export default class DialogoDePagamento extends Vue {
	@Ref() botaoDePagamentos?: { $el: HTMLButtonElement }
	@Ref() botaoDeFrete?: { $el: HTMLButtonElement }
	@Ref() campoDeValorAPagar!: CampoDecimal
	@Ref() cardText!: HTMLLinkElement
	@Ref() botaoDeEncerrarVenda!: { $el: HTMLButtonElement }
	@Ref() seletorDeParcelas!: HTMLInputElement & { isMenuActive: boolean }
	@Ref() confirmacaoDeCpfNaNota!: Confirmacao
	@Ref() confirmacaoDeValorDeVoucher!: Confirmacao
	@Ref() confirmacaoDeValorDeCashback!: Confirmacao
	@Ref() confirmacaoCrediario!: Confirmacao
	@PropSync('mostra', { type: Boolean, default: true }) syncMostra!: boolean
	@PropSync('vendaComErro', { type: Boolean, default: false }) syncVendaComErro!: boolean
	@Ref() botaoConfirmarParcelas!: { $el: HTMLButtonElement }
	@Prop({ type: Function }) onEncerrarVenda?: (venda: Venda) => Promise<void>
	@Prop({ type: Function }) onGerarRecibo?: (emitirDanfe: boolean) => Promise<void>
	@Prop({ type: Function }) onSolicitarNsuOuCodigo?: (tipoDePagamento: TipoDePagamento) => Promise<void>
	@Ref() dialogoDeCupons!: DialogoDeCupons
	@Ref() botaoParaAplicarCupom!: { $el: HTMLButtonElement }
	@Ref() abrirDialogoDeVendedor!: DialogoDeInformarVendedor
	@Ref() abrirDialogoDeModalidade!: DialogoDeInformarModalidade
	@Ref() confirmacaoDeGerarLink!: Confirmacao
	@Ref() dialogoDeEdicaoDeParcelas!: DialogoDeEdicaoDeParcelas
	@Ref() confirmacaoDeTrocaVendedorItens!: Confirmacao
	@Ref() dialogoDeValePresente!: DialogoDeValePresente
	@Prop({ type: Boolean }) existeItemValePresente!: boolean
	@Prop({ type: Array, default: () => [] }) tiposDePagamentoBloqueados!: TipoDePagamento[]

	formatarMoeda = formatarMoeda
	obterTotalDosItensDaVenda = obterTotalDosItensDaVenda
	obterTotalDeTaxas = obterTotalDeTaxas
	obterItensComTaxaAplicada = obterItensComTaxaAplicada
	VendaModule = VendaModule

	@Inject('PagamentoServiceAdapter')
	private pagamentoService!: PagamentoServiceAdapter
	@Inject('DetalhesDoPagamentoServiceAdapter')
	private detalhesDoPagamentoService!: DetalhesDoPagamentoServiceAdapter
	@Inject('CashbackServiceAdapter')
	private cashbackService!: CashbackServiceAdapter
	@Inject('ModalidadeDeVendaServiceAdapter')
	private modalidadeDeVendaAdapter!: ModalidadeDeVendaServiceAdapter

	paginaAtualDeTipos = 0
	tipoSelecionado: TipoDePagamento | null = null
	detalhesInformados: DetalhesDoPagamento | null = null

	pagamento: FormPagamento = criarFormPagamento()
	// Foi passado para escopo fechado para previnir bugs
	// detalhes:  DetalhesDoPagamento | null = null
	mostraSelecaoDeParcelas = false
	mostraCampoNsu = false
	enviandoVenda = false
	encerrandoVenda = false
	gerandoRecibo = false
	gerandoDanfe = false
	errosAoGerarRecibo: string[] = []
	buscaDeTipo = ''
	mostraAguardandoPagamento = false
	criandoDetalhesDoPagamento = false
	formaDePagamentoSelecionado = ''
	contadorTef = 0
	situacaoPagamento = ''
	retornoAdquirente: string | null = null
	aguardandoPagamento = false
	tentativasEfetuarTef = 0
	podeLiberarCrediarioParaCliente = false
	nsu: string | null = null
	codigoAutorizacao: string | null = null
	bandeira: string | null = null
	adquirente: string | null = null
	vendedores: UsuarioBase[] | null = null
	vendedoresPorItem: UsuarioBase[] | null | null = null
	valorAPagar = this.restante
	gerarLink = false
	utilizaPlugin: TipoDePagamento[] | null = null
	@PropSync('value') vendaAtual!: Venda
	findClienteUseCase = new FindClienteUseCase

	get podeGerarOrcamento() {
		return this.turnoDeVenda?.pontoDeVenda.podeGerarOrcamento ||
		(!this.podeEmitirNfce && !this.podeEmitirNfe) || this.existeItemValePresente
	}

	get parcelasComTaxasFormatado() {
	
		return  this.pagamento.tipoDePagamento != null && this.pagamento.tipoDePagamento.parcelasComTaxas != null
			? this.pagamento.tipoDePagamento.parcelasComTaxas.map(e => e.parcelamentos)
				.sort((pA, pB) =>{
					if( pA < pB) return -1
					if( pA > pB) return 1
					return 0
				}) : []
	}

	set venda(venda: Venda) {
		VendaModule.setVendaAtual(venda)
	}

	get venda() {
		return VendaModule.vendaAtual as Venda
	}

	get loja() {
		return VendaModule.lojaDaVenda as Loja
	}

	get vendedoresPorItemAgrupado() {
		if (!this.vendedoresPorItem) return null
		
		const vendedoresUnificados: UsuarioBase[] = []
		this.vendedoresPorItem.forEach(vendedor => {
			if (vendedoresUnificados.filter(vendedorUnificado => vendedorUnificado.id === vendedor.id).length === 0) {
				vendedoresUnificados.push(vendedor)
			}
		})
		
		return vendedoresUnificados
	}

	@Watch('valorAPagar', { immediate: true, deep: true})
	onChangeValorAPagar(valorAPagarAtual) { 

		if (valorAPagarAtual === 0 && this.venda.tipoDeTransacao === 'Devolução') {
			valorAPagarAtual = ''
		}

		this.paginaAtualDeTipos = 0
		this.pagamento.valor = valorAPagarAtual

		if(typeof valorAPagarAtual == 'string') {
			this.pagamento.valor = this.restante
			this.valorAPagar = this.restante
		}
	}

	@Watch('venda.cuponsDeDesconto', { immediate: true, deep: true})
	onChangeCupomDeDesconto() {
		this.valorAPagar = this.restante
	}

	@Watch('venda', {immediate:true, deep:true})
	onChangeVenda() {
		this.paginaAtualDeTipos = 0
	}

	@Watch('restante', { immediate: true, deep: true})
	onChangeRestante() {
		this.valorAPagar = this.restante
	}

	get desabilitarCartoes() {
		const aPagar = Number(this.valorAPagar)
		const aPagarFormatado = Math.round(aPagar * 100) / 100
		const restante = Number(this.restante)
		const restanteFormatado = Math.round(restante * 100) / 100

		return (
			!this.loja.configuracaoDaLoja.trocoParaCartao &&
			restanteFormatado < aPagarFormatado
		)
	}

	get tiposDePagamento(): TipoDePagamento[] {
		let tiposOrdenacao: TipoDePagamento[] = []
		if((this.venda.isConsignado) && !this.venda.isVendaReaberta) {
			return this.turnoDeVenda?.tiposDePagamento.some(tipo => 
				tipo.formaDePagamento === 'Consignado') && (this.venda.isConsignado)
				? this.turnoDeVenda?.tiposDePagamento.filter(tiposDePagamento => 
					tiposDePagamento.formaDePagamento === 'Consignado')
				: []
		}

		else if((this.venda.isDemonstracao) && !this.venda.isVendaReaberta) {
			return this.turnoDeVenda?.tiposDePagamento.some(tipo => 
				tipo.formaDePagamento === 'Demonstração') && (this.venda.isDemonstracao)
				? this.turnoDeVenda?.tiposDePagamento.filter(tiposDePagamento => 
					tiposDePagamento.formaDePagamento === 'Demonstração')
				: []
		}

		else if(this.venda.isBrinde || this.venda.itens.filter(item => item.isBrinde).length > 0) {
			return this.turnoDeVenda?.tiposDePagamento.some(tipo => 
				tipo.formaDePagamento === 'Brinde') ? this.turnoDeVenda?.tiposDePagamento.filter(tiposDePagamento => 
					tiposDePagamento.formaDePagamento === 'Brinde')
				: []
		}

		else {
			tiposOrdenacao = this.turnoDeVenda?.tiposDePagamento
				.filter(
					tipo =>
						((
							(
								(this.venda.tipoDeTransacao === 'Outros' && tipo.transacoes.includes('Devolução')) ||
								(this.venda.tipoDeTransacao !== 'Outros' && tipo.transacoes.includes(this.venda.tipoDeTransacao))
							) ||
							this.ehUmaTroca &&
							tipo.transacoes.includes('Venda')
						) &&
						comparacaoDiatrica(tipo.nome, this.buscaDeTipo)) &&
						(tipo.formaDePagamento !== 'Brinde' ||
						this.venda.itens.filter(item => item.isBrinde).length > 0)
				)
				.filter(
					tipo =>
						((
							(
								(this.venda.tipoDeTransacao === 'Outros' && tipo.transacoes.includes('Devolução')) ||
								(this.venda.tipoDeTransacao !== 'Outros' && tipo.transacoes.includes(this.venda.tipoDeTransacao))
							) ||
							this.ehUmaTroca &&
							tipo.transacoes.includes('Venda')
						) &&
						comparacaoDiatrica(tipo.nome, this.buscaDeTipo)) &&
						(tipo.formaDePagamento !== 'Demonstração' || this.venda.isDemonstracao)
				)
				.filter(
					tipo =>
						tipo.formaDePagamento !== 'Cartão' || !this.desabilitarCartoes,
				)
				|| []
		}	 
		return tiposOrdenacao.sort((a, b) => {
			const tipoTef = ['TEF Crédito', 'TEF Débito', 'TEF Pix'];
			const tipoPosDebito = ['Cartão de Debito - Pos']
			const tipoPosCredito = ['Cartão de Credito - Pos']

			const aIsTef = tipoTef.includes(a.formaDePagamento);
			const bIsTef = tipoTef.includes(b.formaDePagamento);

			const aIsDebit = tipoPosDebito.includes(a.nome);
			const bIsDebit = tipoPosDebito.includes(b.nome);

			const aIsCredit = tipoPosCredito.includes(a.nome);
			const bIsCredit = tipoPosCredito.includes(b.nome);

			if (aIsTef && !bIsTef) return -1;
			if (!aIsTef && bIsTef) return 1;

			if (aIsDebit && !bIsDebit) return -1;
			if (!aIsDebit && bIsDebit) return 1;

			if (aIsCredit && !bIsCredit) return -1;
			if (!aIsCredit && bIsCredit) return 1;
			
			return 0;
		});
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	get ehUmaTroca() {
		return (this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros')
			&& this.venda.itens.filter(item => item.quantidade > 0).length > 0
	}

	get ehDemonstracaoReaberta() {
		return this.venda.isVendaReaberta && this.venda.isDemonstracao 
	}

	get possuiItemParaVenda() {
		return this.venda.itens.filter(itemDaVenda => itemDaVenda.isCompra).length > 0
	}

	created() {
		PluginModule.on('adicionarPagamento', this.adicionarPagamento)
		document.addEventListener('keydown', this.atalhoDeValor)
		document.addEventListener('keydown', this.atalhoDePagamentos)
		document.addEventListener('keydown', this.atalhoDeAplicarCupons)
		this.carregarTiposDePagamentoBloqueados();
	}

	destroyed() {
		PluginModule.removeListener('adicionarPagamento')
		document.removeEventListener('keydown', this.atalhoDeValor)
		document.removeEventListener('keydown', this.atalhoDePagamentos)
		document.removeEventListener('keydown', this.atalhoDeAplicarCupons)
	}

	atalhoDeValor(event: KeyboardEvent) {
		if (!this.syncMostra) return
		if (event.altKey && event.key.toLowerCase() === 'v') {
			event.preventDefault()
			this.campoDeValorAPagar.focus()
		}
	}

	atalhoDePagamentos(event: KeyboardEvent) {
		if (!this.syncMostra) return
		if (!event.altKey || event.key.toLowerCase() !== 'p') return
		if (!this.botaoDePagamentos) return

		event.preventDefault()
		event.stopImmediatePropagation()
		this.botaoDePagamentos.$el.click()
	}

	atalhoDeAplicarCupons(event: KeyboardEvent) {
		if (!this.syncMostra) return
		if (!event.altKey || event.key.toLowerCase() !== 'c') return
		if (!this.botaoParaAplicarCupom) return

		event.preventDefault()
		event.stopImmediatePropagation()
		this.botaoParaAplicarCupom.$el.click()
	}

	get restante() {
		return obterRestanteDaVenda(this.venda)
	}

	get troco() {
		return obterTrocoDaVenda(this.venda)
	}

	async selecionarTipoDePagamento(tipoDePagamento: TipoDePagamento) {
		this.venda.liberarCrediario = false
		const crediarioEMenor = this.crediarioDisponivelNoCliente < Number(this.valorAPagar)
	
		if(crediarioEMenor && tipoDePagamento.formaDePagamento == 'Crediário') {
			const confirmacaoParaLiberarLimite = await this.confirmacaoCrediario.mostrar()

			if(confirmacaoParaLiberarLimite) {
				const autenticacaoValida = await this.podeLiberarCrediario()
				if (!autenticacaoValida) return

				this.venda.liberarCrediario = autenticacaoValida
				this.pagamento.valor = this.valorAPagar
				this.podeLiberarCrediarioParaCliente = true	 
			}
		}

		if (this.campoDeValorAPagar && !this.campoDeValorAPagar.validate()) return

		if (tipoDePagamento.utilizaPlugin) {
			this.gerarLink = await this.confirmacaoDeGerarLink.mostrar()
		}

		const pluginAssumeSelecao = this.gerarLink ? await PluginModule.deixarPluginAssumirControle(
			'selecionarTipoDePagamento',
			{
				valorAPagar: this.valorAPagar,
				venda: this.venda,
				lojaDaVenda: VendaModule.lojaDaVenda,
				tipoDePagamento,
			},
		) : false
		if (pluginAssumeSelecao) return
		if (tipoDePagamento.utilizaPlugin && this.gerarLink && !pluginAssumeSelecao) {
			AlertModule.setWarning("Favor aguardar plugin carregar!")
			return
		}
		
		this.pagamento.tipoDePagamento = tipoDePagamento
		this.tipoSelecionado = tipoDePagamento
		if(tipoDePagamento.formaDePagamento === 'Vale Presente') {
			this.adicionarValePresente()
		} else if (this.tipoSelecionado.parcelasComTaxas != null && this.tipoSelecionado.parcelasComTaxas.length > 0) {
			this.mostraSelecaoDeParcelas = true
			setTimeout(() => {
				this.seletorDeParcelas.focus()
				this.seletorDeParcelas.isMenuActive = true
			}, 170)
		} else (
			this.adicionarNsu(this.pagamento.tipoDePagamento)
		)
	}

	adicionarValePresente() {
		this.dialogoDeValePresente.mostrar()
	}

	adicionarNsu(tipoDePagamento: TipoDePagamento | null) {
		if(!tipoDePagamento) return
		
		this.mostraSelecaoDeParcelas = false

		if(tipoDePagamento.exigeNsu == true || tipoDePagamento.exigeCodAutorizacao == true || tipoDePagamento.exigeBandeira == true || tipoDePagamento.exigeAdquirente == true){
			this.mostraCampoNsu = true
		} else {
			this.adicionarPagamento({
				...this.pagamento,
			})
		}
	}

	get ehDevolucao(){
		return (			
			this.venda.tipoDeTransacao === 'Devolução' && this.tipoSelecionado?.nome === 'Troca'
		)
	}

	get podeEmitirNfce() {
		return (
			(this.total <= 10000 &&
			(!this.venda.cliente ||
				this.venda.cliente?.inscricaoEstadual === 'ISENTO')) &&
			(
				!(this.venda.isBrinde || this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') ||
				this.ehUmaTroca
			) &&
			!this.venda.pedido &&
			this.turnoDeVenda?.pontoDeVenda.podeGerarNFCE &&
			(
				(!this.venda.isConsignado && !this.venda.isDemonstracao) ||
				(this.venda.isVendaReaberta && (this.venda.isDemonstracao || this.venda.isConsignado) &&
					this.venda.itens.filter(item => item.quantidade > 0).length !== 0)
			) &&
			(this.venda.pagamentos.filter(pagamento => pagamento.tipoDePagamento.formaDePagamento === 'Link').length === 0 || !this.gerarLink) &&
			((this.venda.informacoesDeTransporte && this.venda.informacoesDeTransporte.modalidadeDeFrete === 'Sem Frete') || !this.venda.informacoesDeTransporte) &&
			(this.ehDemonstracaoReaberta && this.possuiItemParaVenda || !this.ehDemonstracaoReaberta) && 
			!(this.vendaAtual.pagamentos && this.vendaAtual.pagamentos.length > 0 && this.vendaAtual.pagamentos[0].link?.includes('https://pag.getnet.com.br/')) &&
			!this.existeItemValePresente
		)
	}

	get podeEmitirNfe() {
		return this.turnoDeVenda?.pontoDeVenda.podeGerarNFE &&
		!this.venda.pedido  &&
		(this.venda.pagamentos.filter(pagamento => pagamento.tipoDePagamento.formaDePagamento === 'Link').length === 0 ||
		!this.gerarLink) &&
		((this.venda.itens.filter(item => item.quantidade > 0).length !== 0) || 
		((this.venda.isConsignado || this.venda.isDemonstracao) && this.venda.isVendaReaberta && this.venda.notas.length > 0)) && 
		!(this.vendaAtual.pagamentos && this.vendaAtual.pagamentos.length > 0 && this.vendaAtual.pagamentos[0].link?.includes('https://pag.getnet.com.br/')) &&
		!this.existeItemValePresente
	}

	get total() {
		return obterTotalDaVenda(this.venda)
	}

	obterValorPagamento(pagamento: FormPagamento) {

		let valorDoPagamento = pagamento.valor || this.restante

		if (
			typeof this.creditoDisponivelNoCliente === 'number' &&
			pagamento.tipoDePagamento?.formaDePagamento === 'Voucher'
		) {
			if (this.creditoDisponivelNoCliente < valorDoPagamento)
				valorDoPagamento = this.creditoDisponivelNoCliente
		}

		if (
			typeof this.creditoDisponivelNoCliente === 'number' &&
			pagamento.tipoDePagamento?.formaDePagamento === 'Crediário'
		) {
			if (this.crediarioDisponivelNoCliente < valorDoPagamento && !this.podeLiberarCrediarioParaCliente) {
				valorDoPagamento = this.crediarioDisponivelNoCliente
			} else { 
				this.venda.cliente 
					? this.venda.cliente.disponivelEmCredito = this.crediarioDisponivelNoCliente - Number(pagamento.valor)
					: null
				valorDoPagamento = Number(this.valorAPagar)
			}
			
			this.podeLiberarCrediarioParaCliente = false
		} 

		return valorDoPagamento
	}

	async podeLiberarCrediario() {
		return await VendaModule.autenticarAcaoTelaDeCaixa({ 
			regra:'permite-vendas-acima-do-valor-limite-do-funcionario', 
		})
	}

	adicionarPagamento(pagamento: FormPagamento) {
		if (!pagamento.tipoDePagamento) return
		this.criandoDetalhesDoPagamento = true
		if (
			pagamento.tipoDePagamento.formaDePagamento === 'TEF Crédito' ||
			pagamento.tipoDePagamento.formaDePagamento === 'TEF Débito' ||
			pagamento.tipoDePagamento.formaDePagamento === 'TEF Pix'
		) {
			this.formaDePagamentoSelecionado = pagamento.tipoDePagamento.nome
			this.situacaoPagamento = 'Pagamento Iniciado'
			this.mostraAguardandoPagamento = true

			this.efetuarPagamentoTef(pagamento)
			return
		}
		if(pagamento.tipoDePagamento.formaDePagamento === 'Crediário' && pagamento.tipoDePagamento.alterarVencimentoCrediario){
			return
		}

		this.confirmarPagamento(pagamento)
	}

	async confirmarPagamento(pagamento: FormPagamento) {
		if (!pagamento.tipoDePagamento || (pagamento.valor !== null && !pagamento.valor)) return

		const valor = this.obterValorPagamento(pagamento)

		let valorDaTaxa = 0
		try {
			if (pagamento.tipoDePagamento.parcelasComTaxas && pagamento.qtdeDeParcelas) {
				valorDaTaxa = obterSomaTotalDaTaxaPorItem(
					valor,
					pagamento.tipoDePagamento.parcelasComTaxas,
					pagamento.qtdeDeParcelas,
				)
			}

			this.detalhesInformados = {
				id: '', 
				identificadorExterno: '' ,
				status: '',
				nsu: this.nsu ? this.nsu : '',
				numeroAutorizacao: this.codigoAutorizacao ? this.codigoAutorizacao : '',
				bandeira: this.bandeira ? this.bandeira : '',
				adquirente: this.adquirente ? this.adquirente : '',
				msgAdquirente: '',
				comprovanteAdquirente: '',
				identificadorDaVenda: this.venda.identificador ? this.venda.identificador : '',
				statusDoPagamento: null,
			}
			let detalhes: DetalhesDoPagamento | null = null
			if(pagamento.tipoDePagamento.exigeCodAutorizacao == true || pagamento.tipoDePagamento.exigeNsu == true 
				|| pagamento.tipoDePagamento.exigeAdquirente == true
				|| pagamento.tipoDePagamento.exigeBandeira == true
			) {
				detalhes = await this.detalhesDoPagamentoService.criarPagamento(
					this.detalhesInformados,
				)
			}

			this.mostraCampoNsu = false
			this.criandoDetalhesDoPagamento = false
			this.nsu = null
			this.codigoAutorizacao = null
			this.bandeira = null
			this.adquirente = null
			this.venda = {
				...this.venda,
				pagamentos: [
					...this.venda.pagamentos,
					{
						id: '',
						valor,
						tipoDePagamento: pagamento.tipoDePagamento,
						dataHoraPagamento: new Date(),
						qtdeDeParcelas: pagamento.tipoDePagamento.parcelasComTaxas != null
							? (pagamento.qtdeDeParcelas as number)
							: 0,
						status: pagamento.tipoDePagamento.formaDePagamento === 'Link' && pagamento.tipoDePagamento.utilizaPlugin ? 'Pendente' : 'Pago',
						valorDaTaxa: valorDaTaxa,
						detalhesDoPagamentoId: !detalhes ? pagamento.detalhesDoPagamentoId : detalhes.id,
						link: pagamento.link,
						valePresenteId: pagamento.valePresenteId,
					},
				],
			}

			this.pagamento = criarFormPagamento()
			setTimeout(() => {
				this.cardText.scrollTop = this.cardText.scrollHeight
				this.campoDeValorAPagar && this.campoDeValorAPagar.focus()
			})

		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	efetuarPagamentoTef(pagamento: FormPagamento) {
		setTimeout(async () => {
			this.contadorTef++

			if (this.contadorTef === 1) {
				if (!this.turnoDeVenda) return

				try {
					this.situacaoPagamento = 'Selecionando pagamento'

					if (pagamento.valor === null) {
						pagamento.valor = this.restante
					}

					if (pagamento.tipoDePagamento && pagamento.tipoDePagamento.parcelasComTaxas && pagamento.qtdeDeParcelas && pagamento.valor) {
						const valor = this.obterValorPagamento(pagamento)
						
						const valorDaTaxa = obterSomaTotalDaTaxaPorItem(
							valor,
							pagamento.tipoDePagamento.parcelasComTaxas,
							pagamento.qtdeDeParcelas,
						)

						pagamento.valorDaTaxa = valorDaTaxa
					}

					const detalhes = await this.pagamentoService.efetuarPagamentoTef(
						this.turnoDeVenda.pontoDeVenda.id,
						pagamento,
						this.venda.identificador ? this.venda.identificador : '',
					)

					this.situacaoPagamento = this.validarStatusPagamentoTefDescricao(
						detalhes,
					)

					pagamento.detalhesDoPagamentoId = detalhes.id

					this.efetuarPagamentoTef(pagamento)
				} catch (error: any) {
					if (error.response.status === 504) {
						this.reconsultarDetalheDoPagamento(pagamento)
					} else {
						this.zerarPagamentoTef()
						AlertModule.setError(error)
					}
				}
			} else {
				try {
					if (!this.turnoDeVenda)
						throw new Error('Turno de venda não encontrado')

					const detalhesPagamento = await this.pagamentoService.consultarPagamentoTef(
						this.turnoDeVenda.pontoDeVenda.id,
						pagamento.detalhesDoPagamentoId
							? pagamento.detalhesDoPagamentoId
							: '',
					)

					this.retornoAdquirente = detalhesPagamento.msgAdquirente

					this.situacaoPagamento = this.validarStatusPagamentoTefDescricao(
						detalhesPagamento,
					)

					if (this.validarStatusPagamentoTefFinalizado(detalhesPagamento)) {
						if (this.validarStatusPagamentoTefAprovado(detalhesPagamento)) {
							this.confirmarPagamento(pagamento)
						}

						setTimeout(async () => {
							this.zerarPagamentoTef()
						}, 5000)
					} else {
						this.efetuarPagamentoTef(pagamento)
					}
				} catch (error: any) {
					AlertModule.setError(error)
					this.zerarPagamentoTef()
				}
			}
		}, 5000)
	}

	async reconsultarDetalheDoPagamento(pagamento: FormPagamento) {
		if (!this.turnoDeVenda) return
		if (!this.venda.identificador) return

		this.tentativasEfetuarTef++

		if (this.tentativasEfetuarTef <= 6) {
			try {
				const detalhesPagamento = await this.pagamentoService.consultarUltimoPagamentoTefPelaVenda(this.venda.identificador)
				pagamento.detalhesDoPagamentoId = detalhesPagamento.id

				this.efetuarPagamentoTef(pagamento)
			} catch(error: any) {
				if (error.response.status === 404) {
					setTimeout(async () => {
						this.reconsultarDetalheDoPagamento(pagamento)
					}, 5000)
				} else {
					AlertModule.setError(error)
					this.zerarPagamentoTef()
				}
			}
		} else {
			AlertModule.setError('Não foi possível encontrar os dados de pagamento')
			this.zerarPagamentoTef()
		}
	}

	async cancelarPorIndice(indice) {
		const pagamento = this.venda.pagamentos[indice]
	
		try {
			if(pagamento.tipoDePagamento.formaDePagamento === 'Vale Presente') {
				const idx = this.venda.valesPresentes.findIndex(({id}) => id === pagamento.valePresenteId)
				this.venda.valesPresentes.splice(idx, 1)
			}

			if(!pagamento.detalhesDoPagamentoId) {
				this.venda.pagamentos.splice(indice, 1)
			} else {
				await this.detalhesDoPagamentoService.remove(pagamento.detalhesDoPagamentoId)
				this.venda.pagamentos.splice(indice, 1)
			}
		} catch (error: any) {
			AlertModule.setError(error)
		}
	}

	cancelarPagamentoPorIndice(indice) {
		const pagamento = this.venda.pagamentos[indice]

		this.formaDePagamentoSelecionado = pagamento.tipoDePagamento.nome
		this.situacaoPagamento = 'Cancelamento Iniciado'
		this.mostraAguardandoPagamento = true

		this.cancelarPagamentoTef(pagamento, indice)
	}

	cancelarPagamentoTef(pagamento: Pagamento, indice) {
		setTimeout(async () => {
			this.contadorTef++

			if (pagamento.detalhesDoPagamentoId !== null) {
				if (this.contadorTef === 1) {
					if (!this.turnoDeVenda) return

					try {
						const detalhes = await this.pagamentoService.cancelarPagamentoTef(
							this.turnoDeVenda.pontoDeVenda.id,
							pagamento.detalhesDoPagamentoId,
						)

						this.situacaoPagamento = this.validarStatusPagamentoTefDescricao(
							detalhes,
						)

						this.cancelarPagamentoTef(pagamento, indice)
					} catch (error: any) {
						this.zerarPagamentoTef()
						AlertModule.setError(error)
					}
				} else {
					try {
						if (!this.turnoDeVenda)
							throw new Error('Turno de venda não encontrado')

						const detalhesPagamento = await this.pagamentoService.consultarPagamentoTef(
							this.turnoDeVenda.pontoDeVenda.id,
							pagamento.detalhesDoPagamentoId,
						)

						this.retornoAdquirente = detalhesPagamento.msgAdquirente

						this.situacaoPagamento = this.validarStatusPagamentoTefDescricao(
							detalhesPagamento,
						)

						if (this.validarStatusPagamentoTefFinalizado(detalhesPagamento)) {
							if (
								this.validarStatusCancelamentoTefAprovado(detalhesPagamento)
							) {
								this.venda.pagamentos.splice(indice, 1)
							}

							setTimeout(async () => {
								this.zerarPagamentoTef()
							}, 5000)
						} else {
							this.cancelarPagamentoTef(pagamento, indice)
						}
					} catch (error: any) {
						AlertModule.setError(error)
						this.zerarPagamentoTef()
					}
				}
			} else {
				this.zerarPagamentoTef()
				AlertModule.setError(
					'Pagamento não pode ser cancelado, pois não possúi pagamento com TEF relacionado',
				)
			}
		}, 5000)
	}

	zerarPagamentoTef() {
		this.aguardandoPagamento = false
		this.mostraAguardandoPagamento = false
		this.contadorTef = 0
		this.situacaoPagamento = ''
		this.retornoAdquirente = null
		this.tentativasEfetuarTef = 0
	}

	validarStatusPagamentoTefFinalizado(
		detalhesDoPagamento: DetalhesDoPagamento,
	) {
		switch (detalhesDoPagamento.status) {
			case '5':
			case '6':
			case '18':
			case '19':
				return false
			default:
				return true
		}
	}

	validarStatusPagamentoTefAprovado(detalhesDoPagamento: DetalhesDoPagamento) {
		switch (detalhesDoPagamento.status) {
			case '10':
			case '20':
				return true
			default:
				return false
		}
	}

	validarStatusCancelamentoTefAprovado(
		detalhesDoPagamento: DetalhesDoPagamento,
	) {
		switch (detalhesDoPagamento.status) {
			case '20':
				return true
			default:
				return false
		}
	}

	validarStatusPagamentoTefDescricao(detalhesDoPagamento: DetalhesDoPagamento) {
		switch (detalhesDoPagamento.status) {
			case '5':
			case '6':
				this.aguardandoPagamento = true
				return 'Aguardando pagamento, insira ou aproxime o cartão'
			case '10':
				return 'Pagamento finalizado'
			case '15':
			case '25':
				return 'Pagamento recusado'
			case '18':
			case '19':
				this.aguardandoPagamento = true
				return 'Aguardando cancelamento'
			case '20':
				return 'Pagamento cancelado'
			default:
				return 'Situação do pagamento indefinida'
		}
	}

	@Watch('syncMostra', { immediate: true })
	async onChangeMostra(syncMostra: DialogoDePagamento['syncMostra']) {
		if (!syncMostra) return
		if (!VendaModule.vendaAtual) return

		const itensComVendedor = VendaModule.vendaAtual.itens.filter(item => !!item.vendedor)
		if (itensComVendedor.length > 0) {
			this.vendedoresPorItem = itensComVendedor.map(item => item.vendedor) as UsuarioBase[]
		} else {
			this.vendedoresPorItem = null
		}

		if (this.venda.tipoDeTransacao === 'Devolução') {
			if (!this.ehUmaTroca) {
				const modalidades = await this.modalidadeDeVendaAdapter.find(this.loja.id)

				const modalidadeDevolucao = modalidades.filter(modalidade => modalidade.origem === 'Devolução')

				this.venda.modalidadeDeVenda = modalidadeDevolucao.length > 0 ? modalidadeDevolucao[0] : null
			}
		}

		if (this.ehUmaTroca && this.valorAPagar < 0) {
			const tipoPagamentoDaTroca = this.tiposDePagamento.find(tipoDePagamento => tipoDePagamento.formaDePagamento === 'Voucher' &&
				(tipoDePagamento.transacoes.includes('Devolução') || tipoDePagamento.transacoes.includes('Outros')))

			if (!tipoPagamentoDaTroca) {
				AlertModule.setError('Não foi possível selecionar o voucher para operação de troca, verifique se existe algum configurado para sua loja')
				this.syncMostra = false
				return
			}

			await this.selecionarTipoDePagamento(tipoPagamentoDaTroca)
		}

		if (this.ehDemonstracaoReaberta && !this.possuiItemParaVenda) {
			const tipoPagamentoDaDemonstracao = this.tiposDePagamento.find(tipoDePagamento => tipoDePagamento.formaDePagamento === 'Demonstração' &&
				(tipoDePagamento.transacoes.includes('Devolução')))
			if (!tipoPagamentoDaDemonstracao) {
				AlertModule.setError('Não foi possível selecionar o pagamento de demonstração para operação de demonstração, configure no cadastro de loja')
				this.syncMostra = false
				return
			}

			if (this.valorAPagar > 0) {
				const pagamentoDemonstracao: Pagamento = {
					id: '',
					valor: this.valorAPagar,
					tipoDePagamento: tipoPagamentoDaDemonstracao,
					dataHoraPagamento: new Date(),
					qtdeDeParcelas: 0,
					status: 'Pago',
					valorDaTaxa: null,
					detalhesDoPagamentoId: null,
					link: null,
					valePresenteId: null,
				}

				this.venda.pagamentos.push(pagamentoDemonstracao)
			}
		} else {
			this.pagamento = criarFormPagamento()
			await this.obterDadosDeCampanhaDeCashback()

			setTimeout(() => {
				this.campoDeValorAPagar.focus()
			})
			setTimeout(() => {
				this.questionarUsoDeVoucherDisponivel()
			})
		}
	}

	async questionarUsoDeVoucherDisponivel() {
		if (!this.syncMostra) return
		if (this.valorAPagar === 0) return

		if (!this.creditoDisponivelNoCliente) {
			if(this.cashbackDisponivelAoCliente && this.clientePodeAplicarCashback) {
				this.questionarUsoDeCashbackDisponivel()
			}
			return
		}

		const tipoDePagamentoComVoucher = this.tiposDePagamento.find(
			({ formaDePagamento }) => formaDePagamento === 'Voucher',
		)
		if (!tipoDePagamentoComVoucher) {
			if(this.cashbackDisponivelAoCliente && this.clientePodeAplicarCashback) {
				this.questionarUsoDeCashbackDisponivel()
			}
			return
		}

		const usarVoucher = await this.confirmacaoDeValorDeVoucher.mostrar()
		if (!usarVoucher) {
			if(this.venda.cliente && this.venda.cliente.disponivelEmCashback > 0 
					&& this.venda.valorDeCashbackAplicado === 0 
					&& this.clientePodeAplicarCashback) {
				this.questionarUsoDeCashbackDisponivel()
			}
			return
		}

		this.selecionarTipoDePagamento(tipoDePagamentoComVoucher)
	}

	async questionarUsoDeCashbackDisponivel() {
		if (!this.syncMostra) return


		if (
			(
				(
					this.venda.isVendaReaberta && (
						this.venda.isConsignado || this.venda.isDemonstracao || (!this.venda.isConsignado && !this.venda.isDemonstracao)
					)
				)
				||
				(
					!this.venda.isVendaReaberta && !this.venda.isConsignado && !this.venda.isDemonstracao
				)
			)
		) {
			const usarVoucher = await this.confirmacaoDeValorDeCashback.mostrar()
			if (!usarVoucher) return

			this.aplicarCashback()
		}
	}

	valorDeCashbackASerAplicado = 0
	clientePodeAplicarCashback = false

	async obterDadosDeCampanhaDeCashback() {
		if (!this.venda.cliente) return

		this.clientePodeAplicarCashback = false

		const valorDisponivelCashback = this.venda.cliente.disponivelEmCashback
		const valorItensSemDesconto = obterTotalDosItensSemDesconto(this.venda)
		const valorTotalLiquidoDosItens = obterTotalDosItensDaVenda(this.venda)
		const descontoNoTotalDaVenda = obterDescontoDaVenda(this.venda)

		const campanhas = await this.cashbackService.findCampanhasAplicaveis(
			this.venda.cliente.tipoDeCliente ? this.venda.cliente.tipoDeCliente.id : null,
			VendaModule.turnoDeVenda?.pontoDeVenda.loja.id || '',
		)

		let maiorPrioridade = 0
		campanhas.forEach(campanha => {0
			if (campanha.prioridade > maiorPrioridade) maiorPrioridade = campanha.prioridade
		})

		const campanhaSelecionada = campanhas.filter(campanha => campanha.prioridade === maiorPrioridade)[0]

		if (!campanhaSelecionada) return

		const podeAplicarSeHouverDescontoNoItem = campanhaSelecionada.aplicacoes.filter(aplicacao => aplicacao.tipo === 'DESCONTO_NO_ITEM').length > 0 || !campanhaSelecionada
		const podeAplicarSeHouverDescontoNoTotal = campanhaSelecionada.aplicacoes.filter(aplicacao => aplicacao.tipo === 'DESCONTO_NO_TOTAL').length > 0 || !campanhaSelecionada

		if (
			(
				(
					this.venda.desconto &&
					(
						this.venda.desconto.valor > 0 && podeAplicarSeHouverDescontoNoTotal 
						|| this.venda.desconto.valor === 0
					)
				)
				||
				!this.venda.desconto
			)
			&& 
			(
				(this.venda.itens.filter(item => item.desconto.valor > 0).length > 0 && podeAplicarSeHouverDescontoNoItem)
				|| this.venda.itens.filter(item => item.desconto.valor > 0).length === 0
			)
		) {
			this.clientePodeAplicarCashback = true
		}

		let percentualMaximoAplicavelDeCashback = 100
		if (this.clientePodeAplicarCashback) {
			const aplicacaoPorPercentualMaximo = campanhaSelecionada.aplicacoes.filter(aplicacao => aplicacao.tipo === 'PERCENTUAL_MAXIMO') || []
			let valorDeDescontoASerAplicadoComCashback = 0

			if (aplicacaoPorPercentualMaximo.length > 0) {

				if (!aplicacaoPorPercentualMaximo[0].aplicarNoItemComDesconto && valorItensSemDesconto === 0) {
					this.clientePodeAplicarCashback = false
					return
				}
				percentualMaximoAplicavelDeCashback = aplicacaoPorPercentualMaximo[0].percentualMaximo
				valorDeDescontoASerAplicadoComCashback = aplicacaoPorPercentualMaximo[0].aplicarNoItemComDesconto ? 
					Math.round(((valorTotalLiquidoDosItens + descontoNoTotalDaVenda)*(percentualMaximoAplicavelDeCashback/100))* 100) / 100
					:
					Math.round(((valorItensSemDesconto + descontoNoTotalDaVenda)*(percentualMaximoAplicavelDeCashback/100))* 100) / 100
				
				if (valorDisponivelCashback <= valorDeDescontoASerAplicadoComCashback) {
					this.valorDeCashbackASerAplicado = valorDisponivelCashback
				} else {
					this.valorDeCashbackASerAplicado = valorDeDescontoASerAplicadoComCashback
				}
			} else {
				this.valorDeCashbackASerAplicado = Math.round(valorTotalLiquidoDosItens * 100) / 100
			}
		}
	}

	async aplicarCashback() {
		if (this.venda.desconto) {
			this.venda.desconto.isPercentual = false
			this.venda.desconto.valor = this.valorDeCashbackASerAplicado
		} else {
			this.venda.desconto = {
				isPercentual: false,
				valor: this.valorDeCashbackASerAplicado,
			} as Desconto
		}

		this.venda.valorDeCashbackAplicado = this.valorDeCashbackASerAplicado
		this.venda.cashbackAplicado = true
	}

	get creditoDisponivelNoCliente() {
		if ((this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') && this.valorAPagar <= 0) return undefined
		return this.venda.cliente
			? this.venda.cliente.disponivelEmCredito -
					this.venda.pagamentos
						.filter(
							pagamento =>
								pagamento.tipoDePagamento.formaDePagamento === 'Voucher',
						)
						.reduce((total, pagamento) => total + pagamento.valor, 0)
			: 0
	}

	get cashbackDisponivelAoCliente() {
		if ((this.venda.tipoDeTransacao === 'Devolução' || this.venda.tipoDeTransacao === 'Outros') && this.valorAPagar <= 0) return undefined
		return this.venda.cliente
			? this.venda.cliente.disponivelEmCashback -
					this.venda.pagamentos
						.filter(
							pagamento =>
								pagamento.tipoDePagamento.formaDePagamento === 'Voucher',
						)
						.reduce((total, pagamento) => total + pagamento.valor, 0)
			: 0
	}

	get crediarioDisponivelNoCliente() {
		const pagamentosEmCrediario = this.venda.pagamentos
			.filter(p => p.tipoDePagamento.formaDePagamento === 'Crediário')
			.map(p => p.valor)
			.reduce((total, valor) => total + valor, 0)

		const gruposEconomicos = this.venda.cliente?.gruposEconomicos.filter(grupoEconomico => grupoEconomico.grupoEconomico.id === this.loja.grupoEconomico.id)
		const valoresPorGrupoEconomico: ClienteGrupoEconomico = gruposEconomicos && gruposEconomicos.length > 0 ?
			gruposEconomicos[0]
			:
			{
				limiteDoCrediario: 0,
				disponivelEmCrediario: 0,
			} as ClienteGrupoEconomico

		return (
			(valoresPorGrupoEconomico.disponivelEmCrediario || 0) -
			pagamentosEmCrediario
		)
	}

	voltar() {
		if(this.venda.pagamentos.length > 0){
			this.$emit('voltar')
		}
		else{
			this.venda.cuponsDeDesconto = []
			this.venda.desconto = null
			this.venda.valorDeCashbackAplicado = 0
			this.venda.cashbackAplicado = false
			this.$emit('voltar')
		}
	}

	async encerrarVenda() {
		if (this.encerrandoVenda) return
		if (!this.onEncerrarVenda) return
		try {
			this.enviandoVenda = true
			this.encerrandoVenda = true

			await this.onEncerrarVenda(this.venda)
			this.errosAoGerarRecibo = []
			if (this.$route.query.voltarPraPedido === 'true') {
				this.$router.push({ path: '/pedidos' })
			}
		} catch (error: any) {
			AlertModule.setError((error as AxiosError).response?.data[0])
		} finally {
			this.enviandoVenda = false
			this.encerrandoVenda = false
			this.$route.query.voltarPraPedido === 'false'
			this.gerarLink = false
		}
	}

	async imprimirRecibo(emitirDanfe: boolean) {

		if (this.enviandoVenda) return
		if (!this.onGerarRecibo) return
		
		try {
			const ufCliente = this.venda.enderecoAdicional ? this.venda.enderecoAdicional.endereco.uf : this.venda.cliente?.enderecosAdicionais?.filter(endereco => endereco.isPadrao)[0].endereco.uf

			if(this.loja.endereco.uf !== ufCliente && 
			!this.loja.configuracaoDaLoja.permitirCriarNotaDeDemonstracaoParaForaDoEstado &&
			this.venda.isDemonstracao){
				AlertModule.setError("UF da loja e UF do cliente são diferentes. <br>" +
				"Notas de demonstração não podem ser criadas para outros estados.<br> " +
				"Para prosseguir, altere as configurações de loja.");
				return
			}

			this.enviandoVenda = true
			if (emitirDanfe) {
				this.gerandoDanfe = true
			} else {
				this.gerandoRecibo = true
			}
			this.errosAoGerarRecibo = []

			if ((this.venda.cliente || this.venda.cpfDoCliente) && !this.venda.isBrinde) {
				this.venda.exibirCpfNaNota = await this.confirmacaoDeCpfNaNota.mostrar()
			}

			await this.onGerarRecibo(emitirDanfe) 
			this.errosAoGerarRecibo = []
		} catch (error: any) {
			const errAxios: AxiosError = error as AxiosError
			if (
				errAxios.response &&
				errAxios.response.data &&
				Array.isArray(errAxios.response.data)
			) {
				this.errosAoGerarRecibo = errAxios.response.data
			} else {
				AlertModule.setError(error)
			}
		} finally {
			this.enviandoVenda = false
			this.gerandoRecibo = false
			this.gerandoDanfe = false
			this.gerarLink = false
		}
	}

	get pagamentoConcluido() {
		return this.ehUmaTroca ? 
			this.valorAPagar <= 0
			: fixarCasasDecimais(obterValorAPagar(this.venda), 2) === 0
	}

	mostraTrocaDePagamento() {
		this.$router
			.push({
				name: this.$route.name as string,
				query: { devolucao: null },
			})
			.catch()
	}

	@Watch('pagamentoConcluido', { immediate: true })
	onPagamentoConcluido(concluido: boolean) {
		if (!concluido) this.errosAoGerarRecibo = []
		if (!concluido || !this.syncMostra) return
		if (
			this.turnoDeVenda &&
			!this.turnoDeVenda.pontoDeVenda.podeGerarOrcamento && this.turnoDeVenda.pontoDeVenda.podeGerarNFCE
		)
			return

		setTimeout(() => {
			this.botaoDeEncerrarVenda.$el.focus()
		})
	}

	get displayCodigoDaConfiguracaoDeCupom() {
		return this.venda.cuponsDeDesconto && this.venda.cuponsDeDesconto.length
			? this.venda.cuponsDeDesconto[0].codigo
			: ''
	}

	abrirDialogoDeCupons() {
		this.dialogoDeCupons.mostrar()
	}

	aplicarValePresenteNaVenda(valePresente: ValePresente) {
		const tipoPagamentoValePresente = this.tiposDePagamento.find(tipoDePagamento => tipoDePagamento.formaDePagamento === 'Vale Presente')
		const valorTotalDoValePresente = (valePresente.valor - valePresente.valorUtilizado)
		if(!tipoPagamentoValePresente) return 

		const pagamentoValePresente: Pagamento = {
			id: '',
			valor: valorTotalDoValePresente > this.valorAPagar ? this.valorAPagar : valorTotalDoValePresente,
			tipoDePagamento: tipoPagamentoValePresente,
			dataHoraPagamento: new Date(),
			qtdeDeParcelas: 0,
			status: 'Pago',
			valorDaTaxa: null,
			detalhesDoPagamentoId: null,
			link: null,
			valePresenteId: valePresente.id,
		}

		this.venda = {
			...this.venda,
			valesPresentes: [...this.venda.valesPresentes, valePresente],
			pagamentos: [...this.venda.pagamentos, pagamentoValePresente],
		}
	}

	aplicarCupomNaVenda(cupom: CupomDeDesconto) {
		this.venda = {
			...this.venda,
			cuponsDeDesconto: [...this.venda.cuponsDeDesconto, cupom],
		}
	}

	removerCuponsAplicados() {
		this.venda = {
			...this.venda,
			cuponsDeDesconto: [],
		}
	}

	trocarDeVendedor(){
		this.abrirDialogoDeVendedor.mostrar()
	}

	trocarDeModalidade(){
		this.abrirDialogoDeModalidade.mostrar()
	}

	get vendedorDaVenda() {
		if (!VendaModule.vendaAtual) {
			return 'Sem vendedor'
		} else {
			return VendaModule.vendaAtual.vendedor || 'Sem vendedor'
		}
	}

	@Watch('vendedorDaVenda', {immediate: true})
	onChangeVendedorDaVenda() {
		if(!VendaModule.vendaAtual) return
		this.vendedores = !VendaModule.vendaAtual.vendedor ?[] : VendaModule.vendaAtual.vendedor

		const itensComVendedor = VendaModule.vendaAtual.itens.filter(item => !!item.vendedor)
		if (itensComVendedor.length > 0) {
			this.vendedoresPorItem = itensComVendedor.map(item => item.vendedor) as UsuarioBase[]
		} else {
			this.vendedoresPorItem = null
		}
	}

	removerVendedorDaVenda(id: string) {
		if (!this.venda.vendedor) return
		this.venda.vendedor = this.venda.vendedor.filter(vendedor => vendedor.id !== id) 
	}

	displayPerfilTooltip(id: string) {
		const perfil = UsuariosModule.perfil(id)
		return perfil ? `${perfil.nome} - ` : ''
	}

	@Watch('buscaDeTipo')
	onChangeBuscaDeTipo() {
		this.paginaAtualDeTipos = 0
	}

	abrirEdicaoDeParcelas() {
		if (this.pagamento.tipoDePagamento?.formaDePagamento === 'Crediário' && this.pagamento.tipoDePagamento.alterarVencimentoCrediario) {			
			this.dialogoDeEdicaoDeParcelas.mostrar(this.pagamento);			
		}
	}

	alterarVencimento(parcelas: ParcelasCrediario[]) {
		if (!this.pagamento.tipoDePagamento) return

		this.pagamento.tipoDePagamento.parcelasCrediario = parcelas
		this.confirmarPagamento(this.pagamento)
	}

	calcularTaxa(qtdeParcelas: number, pagamento: FormPagamento) {
		const valor = this.obterValorPagamento(pagamento)
		if (pagamento.tipoDePagamento && pagamento.tipoDePagamento.parcelasComTaxas && qtdeParcelas) {
			const valorDaTaxa = obterSomaTotalDaTaxaPorItem(
				valor,
				pagamento.tipoDePagamento.parcelasComTaxas,
				qtdeParcelas,
			)
			
			if (valorDaTaxa > 0) {

				const parcelaComTaxas = pagamento.tipoDePagamento.parcelasComTaxas.filter(
					parcela => parcela.parcelamentos === qtdeParcelas,
				)[0]

				const valorPorParcelaComTaxa = (valor + valorDaTaxa)

				return valorPorParcelaComTaxa > 0 ? 'R$ '+formatarMoeda(valorPorParcelaComTaxa) + `(${parcelaComTaxas.taxas}%)` : 'Sem Taxa'
			}
		}

		return 'Sem Taxa'
	}

	solicitarTrocaVendedorItens() {
		this.confirmacaoDeTrocaVendedorItens.mostrar()
	}

	confirmarTrocaVendedorItens() {
		if (!this.venda) return

		this.venda.itens.forEach(item => {
			if (!this.venda) return
			if (!this.venda.vendedor) {
				item.vendedor = null
				return
			}

			item.vendedor = this.venda.vendedor[0]
		})

		this.onChangeVendedorDaVenda()
	}

	async carregarTiposDePagamentoBloqueados(){
		try {
			this.tiposDePagamentoBloqueados = await this.findClienteUseCase.listarTiposDePagamentoBloqueadosCliente(this.venda.cliente?.id, this.loja.id)
		} catch (error) {
			console.error('Erro ao carregar tipos de pagamento bloqueados:', error);
		}
	}

	@Watch('syncMostra', { immediate: true })
	async onChangeMostraTiposDePagamentoBloqueados(syncMostra: boolean) {
		if (syncMostra) {
			await this.carregarTiposDePagamentoBloqueados();
		}
	}

}
